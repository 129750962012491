<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-8">
        <input
          type="text"
          class="form-control"
          placeholder="Ingrese su tracking de seguimiento"
          v-model="tracking"
        />
      </div>
      <div class="col-4">
        <button class="btn btn-primary" @click="rastrear">Rastrear</button>
      </div>
    </div>

    <div class="row" v-if="rastreoInfo">


      <div class="col-12 mt-5" v-if="paqueteriaV.value == 'globalpaq'">
        <h3>{{ rastreoInfo.paqueteria }}</h3>
        <h4>{{ rastreoInfo.tracking }}</h4>
        <h3>{{ rastreoInfo.ultimo_evento.estatus }}</h3>
        <h5>{{ rastreoInfo.ultimo_evento.fecha }}</h5>
      </div>

      <div class="col-12 mt-5" v-if="paqueteriaV.value != 'globalpaq'">
        <h3>{{ rastreoInfo.paqueteria }}</h3>
        <h4>{{ rastreoInfo.tracking }}</h4>
        <h3>{{ rastreoInfo.eventos[0].descripcion }}</h3>
        <h5>{{ rastreoInfo.eventos[0].fecha }}</h5>
      </div>

      <div class="col-6 mt-5" v-show="rastreoInfo.desde">
        <div class="row">
          <div class="col-12 fw-bold">DESDE:</div>
          <div class="col-12">{{ rastreoInfo.desde }}</div>
        </div>
      </div>
      <div class="col-6 mt-5" v-show="rastreoInfo.para">
        <div class="row">
          <div class="col-12 fw-bold">PARA:</div>
          <div class="col-12">{{ rastreoInfo.para }}</div>
        </div>
      </div>

      <div class="col-12 my-4">
        <nav class="nav">
          <a
            href="#"
            class="nav-link"
            :class="{ active: historial }"
            @click="setHistorialTrue"
            >Historial de Trasporte</a
          >
          <a
            href="#"
            class="nav-link"
            :class="{ active: paquete }"
            @click="setPaqueteTrue"
            >Datos de envio</a
          >
        </nav>
      </div>
      <div class="col-12" v-if="historial">
        <div class="row">
          <div class="col-12">
            <table class="table">
              <tbody>
                <tr v-for="evento in rastreoInfo.eventos" :key="evento">
                  <td scope="row">{{ evento.fecha }}</td>
                  <td>{{ evento.descripcion }}</td>
                  <td>{{ evento.ubicacion }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-12" v-if="paquete">
        <div class="row">
          <div class="col-md-4 my-2" v-show="rastreoInfo.tracking">
            <div class="row">
              <div class="col-12 fw-bold">NUMERO DE SEGUIMIENTO</div>
              <div class="col-12">{{ rastreoInfo.tracking }}</div>
            </div>
          </div>

          <div class="col-md-4 my-2" v-show="rastreoInfo.servicio">
            <div class="row">
              <div class="col-12 fw-bold">SERVICIO</div>
              <div class="col-12">{{ rastreoInfo.servicio }}</div>
            </div>
          </div>

          <div class="col-md-4 my-2" v-show="rastreoInfo.paquete.peso">
            <div class="row">
              <div class="col-12 fw-bold">PESO</div>
              <div class="col-12">
                {{ rastreoInfo.paquete.peso }}
                {{ rastreoInfo.paquete.unidadPeso || "" }}
              </div>
            </div>
          </div>

          <div class="col-md-4 my-3" v-show="rastreoInfo.paquete.alto">
            <div class="row">
              <div class="col-12 fw-bold">DIMENSIONES</div>
              <div class="col-12">
                {{ rastreoInfo.paquete.alto }}X{{
                  rastreoInfo.paquete.ancho
                }}X{{ rastreoInfo.paquete.largo }}
                {{ rastreoInfo.paquete.unidadMedidas || "" }}
              </div>
            </div>
          </div>

          <div class="col-md-4 my-3" v-show="rastreoInfo.paquete.embalaje">
            <div class="row">
              <div class="col-12 fw-bold">EMBALAJE</div>
              <div class="col-12">
                {{ rastreoInfo.paquete.embalaje }}
              </div>
            </div>
          </div>

          <div class="col-md-4 my-3" v-show="rastreoInfo.paquete.piezas">
            <div class="row">
              <div class="col-12 fw-bold">TOTAL DE PIEZAS</div>
              <div class="col-12">
                {{ rastreoInfo.paquete.piezas }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Modulo principal de rastreo
 * importa useRastreo donde esta toda la logica
 */
import useRastreo from "../hooks/useRastreo";
export default {
  name: "Rastreo",
  setup() {
    const {
      tracking,
      rastrear,
      rastreoInfo,
      setHistorialTrue,
      setPaqueteTrue,
      historial,
      paquete,
      paqueteriaV,
    } = useRastreo();

    return {
      tracking,
      rastrear,
      rastreoInfo,
      setHistorialTrue,
      setPaqueteTrue,
      historial,
      paquete,
      paqueteriaV,
    };
  },
};
</script>

<style scoped>
.btn-primary {
  background-color: #ed7f00;
  border: none;
  color: #fff;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background-color: rgba(239, 107, 1, 0.8);
  border: none;
  color: #fff;
  outline: 1px solid #ed7f00 !important;
  box-shadow: none;
  border-color: transparent;
}

input:focus,
input:active {
  outline: 1px solid #ed7f00 !important;
  box-shadow: none;
  border-color: transparent;
}
a.nav-link {
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
}

a:hover {
  border: 0;
  color: #ed7f00;
  font-weight: 600;
  border-bottom: 3px solid #ed7f00;
  transition: all 0.2s ease;
}

a.active {
  border: 0;
  color: #ed7f00;
  font-weight: 600;
  border-bottom: 3px solid #ed7f00;
  transition: all 0.2s ease;
}
</style>
